import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PesquisaPublicaVO } from '../../../../global/models/pesquisaPublicaVO';
import { errorHandler } from '../../../../global/utils/errorhandler';
import { environment } from '../../environments/environment.prod';

/**
 * Um equipamento é representado por seu contrato
 */
@Injectable({
  providedIn: "root"
})
export class EquipamentoService {

  constructor(private http: HttpClient) {
  }

  API_P_URL = environment.serviceBasePublicUrl;

  pesquisaPublicaResultado: PesquisaPublicaVO[];

  publicoConsultaEquipamento(codigoMunicipal?: number, codigoLogradouro?: number, numeroLogradouro?: number): Observable<PesquisaPublicaVO[]> {
    let params = new HttpParams();

    if (codigoMunicipal) {
      params = params.set('cm', codigoMunicipal.toString());
    }

    if (codigoLogradouro) {
      params = params.set('cdl', codigoLogradouro.toString());
    }

    if (numeroLogradouro) {
      params = params.set('nm', numeroLogradouro.toString());
    }

    return this.http
      .get<PesquisaPublicaVO[]>(`${this.API_P_URL}/pesquisaEquipamento`, { params: params }).pipe(
        tap((resultado) => this.pesquisaPublicaResultado = resultado),
        catchError(errorHandler));
  }

}
