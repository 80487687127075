import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export class AlertService {

  constructor() { }

  private _alert: { mensagem?: string, tipo?: string, dismissible?: boolean, dismissOnTimeout?: number };

  /**
   * Cria um novo alerta ao usuario.
   * Padrão: tipo info, 10 segundos para sumir, pode ser removido manualmente
   *
   * @param mensagem mensagem exibida
   * @param tipo erro | ok | info | aviso
   * @param secondsToTimeout tempo de exibicao da mensagem
   * @param dismissible se pode ser removido manualmente o alerta
   */
  criaAlerta(mensagem: string, tipo: string = 'info', secondsToTimeout: number = 10, dismissible: boolean = true) {
    tipo = this.traduzTipo(tipo);
    secondsToTimeout *= 1000;
    this._alert = null;
    this._alert = { mensagem: mensagem, tipo: tipo, dismissible: dismissible, dismissOnTimeout: secondsToTimeout };
  }

  get alert() {
    return this._alert;
  }

  set alert(value) {
    this._alert = value;
  }

  get isAtivo() {
    return !!this._alert;
  }

  private traduzTipo(tipo: string) {
    switch (tipo) {
      case "danger":
      case "erro":
      case "error":
        return "danger";
      case "success":
      case "sucesso":
      case "ok":
        return "success";
      case "warning":
      case "aviso":
      case "cuidado":
        return "warning";
      default:
        return "info";
    }
  }
}
