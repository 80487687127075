import { Component } from '@angular/core';
import { LogradouroCdl } from 'procempa-cdl-lib';
import { PesquisaPublicaVO } from '../../../global/models/pesquisaPublicaVO';
import { tap } from 'rxjs/operators';
import { EquipamentoService } from './services/eqp.publico.service';

@Component({
  selector: 'etp-publico-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   // Critérios de Pesquisa
   criterioCodigoMunicipal: number = null;

   private logradouroCdl: LogradouroCdl;
   mensagemErro: string;

   // paginacao
   qtdPorPagina: number = 10;

   // view
   contratoEquipTela: PesquisaPublicaVO[];
   contratoEquip: PesquisaPublicaVO[];

   constructor(
     private equipamentoService: EquipamentoService
   ) { }

   ngOnInit() {
   }

   pesquisarEquipamento() {

     let cdl: number, ncdl: number;

     if (this.logradouroCdl) {
       cdl = this.logradouroCdl.codigoLogradouro;
       ncdl = this.logradouroCdl.numero;
     }

     if (!this.criterioCodigoMunicipal && !cdl && !ncdl) {
       return;
     }

     this.pesquisarGeral(this.criterioCodigoMunicipal, cdl, ncdl).subscribe();
   }

   private pesquisarGeral(...args) {

     return this.equipamentoService.publicoConsultaEquipamento(args[0], args[1], args[2]).pipe(
       tap((contratos) => {
         this.contratoEquip = contratos;
         this.contratoEquipTela = contratos.slice(0, this.qtdPorPagina);
         this.mensagemErro = undefined;
       },
       (error) => {
         this.contratoEquip = [];
         this.contratoEquipTela = [];
         this.mensagemErro = error;
       }));
   }

   isContratoVencido(busca: PesquisaPublicaVO) {
     if (busca && busca.contrato) {
       return false;
     }

     return true;
   }

   getIndex(busca: PesquisaPublicaVO) {
     return this.contratoEquip.indexOf(busca);
   }

   receiveResults(logradouroCdl: LogradouroCdl) {
     this.logradouroCdl = logradouroCdl;
   }

   paginar($event: any) {
     let pagina = $event - 1;
     let inicio = pagina * this.qtdPorPagina;
     let final = inicio + this.qtdPorPagina;
     this.contratoEquipTela = this.contratoEquip.slice(inicio, final);
   }
}
