
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PesquisaPublicaVO } from '../../../../../global/models/pesquisaPublicaVO';
import { VistoriaVO } from '../../../../../global/models/vistoriaVO';
import { EquipamentoService } from '../../services/eqp.publico.service';

@Component({
  selector: 'etp-equipamento-publico-detalhes',
  templateUrl: './equipamento-publico-detalhes.component.html',
  styleUrls: ['./equipamento-publico-detalhes.component.scss']
})
export class EquipamentoPublicoDetalhesComponent implements OnInit {

  constructor(
    private equipamentoService: EquipamentoService,
    private route: ActivatedRoute,
    private router: Router) { }

  equipamentoContrato: PesquisaPublicaVO;

  vistorias: VistoriaVO[];
  vistoriasTela: VistoriaVO[];

  isEquipamentoTab = true;
  isVistoriasTab = false;
  isEmpresaTab = false;
  qtdPorPagina = 5;
  tabIndex: number = 1;

  endereco: string;

  ngOnInit() {
    if (!this.equipamentoService.pesquisaPublicaResultado) {
      this.router.navigate(['/publico']);
    } else {

      this.route.paramMap.subscribe(param => {

        let index = param.get('index');
        this.equipamentoContrato = this.equipamentoService.pesquisaPublicaResultado[index];

        this.vistorias = this.equipamentoContrato.vistorias;
        this.vistoriasTela = this.vistorias && this.vistorias.slice(0, this.qtdPorPagina) || [];

        let eqp = this.equipamentoContrato.equipamento || {};
        let cdl = eqp.imovelCodLogradouro;
        let ncdl = +eqp.imovelNumero;

        // if (cdl && ncdl) { this.cdlService.getLogradouro(cdl, ncdl).pipe(map(log => log.enderecoFormatado),tap(endFormatado => this.endereco = endFormatado),).subscribe(); }
      });
    }
  }

  mudaTab(i: number) {
    this.tabIndex = i;
    switch (i) {
      case 1:
        this.isEquipamentoTab = true;
        this.isVistoriasTab = false;
        this.isEmpresaTab = false;
        break;
      case 2:
        this.isEquipamentoTab = false;
        this.isVistoriasTab = true;
        this.isEmpresaTab = false;
        break;
      case 3:
        this.isEquipamentoTab = false;
        this.isVistoriasTab = false;
        this.isEmpresaTab = true;
        break;
      default:
        break;
    }
  }

  paginar($event: any) {
    let pagina = $event - 1;
    let inicio = pagina * this.qtdPorPagina;
    let final = inicio + this.qtdPorPagina;
    this.vistoriasTela = this.vistorias.slice(inicio, final);
  }

  vistoriasTrackBy(index: number, item: VistoriaVO) {
    return item.id;
  }

}
