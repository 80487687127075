import { Component, OnInit } from '@angular/core';
import { AlertService } from './alert.service';

@Component({
  selector: 'etp-global-info-alert',
  templateUrl: './info.alert.component.html',
  styleUrls: ['./info.alert.component.scss']
})
export class InfoAlertComponent implements OnInit {

  constructor(private alertService: AlertService) { }

  ngOnInit() {
  }

  get mostrar() {
    return this.alertService.isAtivo;
  }

  get tipo() {
    return this.alertService.isAtivo ? this.alertService.alert.tipo : null;
  }

  get timeout() {
    return this.alertService.isAtivo ? this.alertService.alert.dismissOnTimeout : null;
  }

  get dismiss() {
    return this.alertService.isAtivo ? this.alertService.alert.dismissible : null;
  }

  get mensagem() {
    return this.alertService.isAtivo ? this.alertService.alert.mensagem : '';
  }

  get prefixo() {
    if (this.alertService.isAtivo) {
      switch (this.alertService.alert.tipo) {
        case "success":
          return "glyphicon glyphicon-ok-circle"
        case "warning":
          return "glyphicon glyphicon-warning-sign"
        case "danger":
          return "glyphicon glyphicon-ban-circle"
        default:
          return "glyphicon glyphicon-info-sign";
      }
    }
  }

  alertFechado() {
    this.alertService.alert = null;
  }
}
