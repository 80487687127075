import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogradouroCdl } from 'procempa-cdl-lib';

@Component({
  selector: 'etp-procempa-cdl',
  templateUrl: './cdl-pesquisa.component.html',
  styleUrls: ['./cdl-pesquisa.component.scss']
})

export class EtpCdlPesquisaComponent implements OnInit {

  // logradouro selecionado
  @Output() onSelecionaEndereco = new EventEmitter<LogradouroCdl>();
  // logradouro previo
  @Input() logradouroCdl: LogradouroCdl;
  // mostra input logradouro, cep e número
  @Input() isCompleto = true;

  // flags
  ok = false
  notFound = false;

  logra: string;
  numero: string;
  codigo: string;

  constructor() { }

  ngOnInit() {
  }

  get placeholderLogradouro(){
    return this.isCompleto ? 'Ex.: ipiranga' : 'Ex.: ipiranga';
  }

  selecionaEndereco(logradouroCdl?: LogradouroCdl) {
    this.notFound = logradouroCdl == null;
    this.ok = !this.notFound;

    this.logradouroCdl = logradouroCdl;
    this.onSelecionaEndereco.emit(logradouroCdl);
  }

}
