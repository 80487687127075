<div>
  <div class="panel">

    <div class="panel-heading">
      <ul class="nav nav-tabs">
        <li role="presentation" [class.active]="isEquipamentoTab">
          <a class="btn" (click)="mudaTab(1)">Equipamento</a>
        </li>
        <li role="presentation" [class.active]="isVistoriasTab">
          <a class="btn" (click)="mudaTab(2)">Vistorias</a>
        </li>
        <li role="presentation" [class.active]="isEmpresaTab">
          <a class="btn" (click)="mudaTab(3)">Empresa</a>
        </li>
      </ul>
    </div>

    <div class="panel-body">
      <div [ngSwitch]="tabIndex">
        <!-- aba 1 - equipamento -->
        <div *ngSwitchCase="1">
          <dl>
            <dt class="">Código Municipal</dt>
            <dd>{{ equipamentoContrato?.equipamento?.codigoMunicipal }}</dd>

            <dt class="">Tipo</dt>
            <dd>{{ equipamentoContrato?.equipamento?.tipoEquipamento }}</dd>

            <dt class="">Capacidade</dt>
            <dd>{{ equipamentoContrato?.equipamento?.capacidadeCarga }}</dd>

            <dt class="">Marca</dt>
            <dd>{{ equipamentoContrato?.equipamento?.marca }}</dd>

            <dt class="">Nº Fabricante</dt>
            <dd>{{ equipamentoContrato?.equipamento?.serialFabricante }}</dd>

            <dt class="">Endereço</dt>
            <dd>{{ endereco }} </dd>

            <dt class="">Complemento</dt>
            <dd>{{ equipamentoContrato?.equipamento?.imovelComplemento }} </dd>

            <dt class="">Nome Imóvel</dt>
            <dd>{{ equipamentoContrato?.equipamento?.imovelNomePredio }}</dd>

            <dt class="">Frentes</dt>
            <dd>{{ equipamentoContrato?.equipamento?.imovelFrentes }}</dd>

            <dt>Responsável Técnico</dt>
            <dd>{{ equipamentoContrato?.contrato?.responsavelTecnico || "Sem contrato"}}</dd>
          </dl>
        </div>

        <!-- aba 2 - vistorias -->
        <div *ngSwitchCase="2">
          <accordion>
            <accordion-group [panelClass]="'panel-default'" *ngFor="let vistoria of vistoriasTela; trackBy: vistoriasTrackBy">
              <div accordion-heading>
                Vistoria {{vistoria.tipoVistoria }} realizada em: {{vistoria?.dataRealizacao | date:'longDate':'':'pt'}}
              </div>

              <dl>
                <dt>Data Realização</dt>
                <dd>{{ vistoria?.dataRealizacao | date:'shortDate':'':'pt' }}</dd>

                <dt>Vistoriador</dt>
                <dd>{{ vistoria?.vistoriador }}</dd>

                <dt>Tipo de Vistoria</dt>
                <dd>{{ vistoria?.tipoVistoria }}</dd>

                <dt>Situação do Equipamento</dt>
                <dd>{{ vistoria?.situacaoEquipamento }}</dd>

                <!-- <dt>Descrição geral do Equipamento</dt>
                <dd>{{ vistoria?.descricaoGeralEquipamento }}</dd>

                <dt>Recomendações</dt>
                <dd>{{ vistoria?.recomendacao }}</dd>

                <dt>Observações</dt>
                <dd>{{ vistoria?.observacao }}</dd>

                <dt>Parecer Recusado pelo Responsável</dt>
                <dd>{{ vistoria?.recusaResponsavel ? 'Sim' : 'Não' }}</dd>

                <dt>Motivo da Recusa</dt>
                <dd>{{ vistoria?.motivoRecusaResponsavel }}</dd> -->
              </dl>
            </accordion-group>
          </accordion>
          <div *ngIf="vistorias && vistorias.length">
            <etp-global-paginar [qtdPorPagina]="qtdPorPagina" [totalRegistros]="vistorias?.length" [qtdAdjacentes]="3" (onPaginate)="paginar($event)"></etp-global-paginar>
          </div>
        </div>

        <!-- aba 3 - empresa -->
        <div *ngSwitchCase="3">
          <dl>
            <dt>Nome</dt>
            <dd>{{ equipamentoContrato?.contrato?.empresaVO?.nome }}</dd>

            <dt>E-mail</dt>
            <dd>{{ equipamentoContrato?.contrato?.empresaVO?.email }}</dd>

            <dt>Telefone</dt>
            <dd>{{ equipamentoContrato?.contrato?.empresaVO?.telefone }}</dd>
          </dl>
        </div>

      </div>
    </div>

    <div class="panel-footer clearfix">
      <div class="pull-right">
        <button type="button" class="btn" [routerLink]="['/publico']">Voltar</button>
      </div>
    </div>

  </div>
</div>
