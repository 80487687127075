<etp-global-info-alert></etp-global-info-alert>

<form class="form-horizontal" #f="ngForm" (ngSubmit)="pesquisarEquipamento()">
  <div class="panel panel-default">
    <div class="panel-heading">Critérios de Pesquisa</div>

    <div class="panel-body">

      <div class="form-group">
        <label for="inputCriterioCodigoMunicipal" class="col-sm-2 control-label">Código Municipal</label>
        <div class="col-sm-4">
          <input type="text" [(ngModel)]="criterioCodigoMunicipal" class="form-control" name="inputCriterioCodigoMunicipal" id="idCriterioCodigoMunicipal"
            placeholder="Código Municipal">
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label"></label>
        <div class="col-sm-10">
          <p class="text-info">
            <strong>Por favor, informe o NOME e NÚMERO do logradouro separados por vírgula. OBRIGATÓRIO!</strong>
          </p>
        </div>
      </div>
      <etp-procempa-cdl [isCompleto]="false" (onSelecionaEndereco)="receiveResults($event)"></etp-procempa-cdl>
    </div>

    <div class="panel-footer clearfix">
      <div class="pull-right">
        <button type="submit" class="btn btn-primary">Pesquisar</button>
      </div>
    </div>

  </div>

  <div class="panel panel-primary" *ngIf="contratoEquip && contratoEquip.length">
    <div class="panel-heading">
      <h3 class="panel-title">Lista de Equipamento</h3>
    </div>
    <div class="panel-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="col-md-2 ">Código Municipal</th>
            <th class="col-md-2 ">Empresa de Manutenção</th>
            <th class="col-md-2 ">Tipo de Equipamento</th>
            <th class="col-md-4 ">Nome Imóvel</th>
          </tr>
        </thead>
        <tr class="collection-item " *ngFor="let pesquisa of contratoEquipTela">
          <td>
            <a [routerLink]="['./detalhes', getIndex(pesquisa)]"> {{ pesquisa?.equipamento.codigoMunicipal }}</a>
          </td>
          <td [ngSwitch]="isContratoVencido(pesquisa)">
            <span *ngSwitchCase="true" class="text-warning">Sem empresa ativa</span>
            <span *ngSwitchDefault>{{ pesquisa?.contrato?.empresaVO.nome }}</span>
          </td>
          <td>{{ pesquisa?.equipamento.tipoEquipamento }}</td>
          <td>{{ pesquisa?.equipamento.imovelNomePredio }}</td>
        </tr>
      </table>
      <etp-global-paginar [qtdPorPagina]="qtdPorPagina" [totalRegistros]="contratoEquip?.length" [qtdAdjacentes]="3" (onPaginate)="paginar($event) "></etp-global-paginar>
    </div>
  </div>

</form>
