import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoAlertComponent } from './info-alert/info.alert.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginarComponent } from './paginar/paginar.component';
import { EtpCdlPesquisaComponent } from './cdl/cdl-pesquisa.component';
import { CdlLibModule } from 'procempa-cdl-lib';
import { FormsModule } from '@angular/forms';
import { MyDatePickerComponent } from './my-date-picker/my-date-picker.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';


@NgModule({
  declarations: [
    InfoAlertComponent,
    PaginarComponent,
    EtpCdlPesquisaComponent,
    MyDatePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    CdlLibModule,
    AlertModule.forRoot(),
    AngularMyDatePickerModule
  ],
  exports: [
    InfoAlertComponent,
    PaginarComponent,
    EtpCdlPesquisaComponent,
    MyDatePickerComponent
  ]
})
export class GlobalModule { }
