<div procempaCdl [codigo]="logradouroCdl?.codigoLogradouro" [numero]="logradouroCdl?.numero" (inicial)="logradouroCdl = $event">

  <input cdlCodigo [ngModel]="logradouroCdl?.codigoLogradouro" hidden aria-hidden="true">

  <div class="form-group has-feedback">
    <label for="cdl-logradouro" class="col-sm-2 control-label">Logradouro</label>
    <div class="col-sm-4">
      <input cdlLogradouro autocomplete="off" [(ngModel)]="logra" type="text" name="cdl-logradouro"
        id="cdl-logradouro" class="form-control" [placeholder]="placeholderLogradouro" required (seleciona)="selecionaEndereco($event)">
    </div>

    <label for="inputNumeroImovel" class="col-sm-1 control-label">Número</label>
    <div class="col-sm-4">
      <input cdlNumero [(ngModel)]="numero" required autocomplete="off" class="form-control"
        name="inputNumeroImovel" id="inputNumeroImovel" placeholder="informe o número do endereço">
    </div>
  </div>

  <div class="form-group" *ngIf="isCompleto">
    <label for="inputCepImovel" class="col-sm-2 control-label">CEP</label>
    <div class="col-sm-3">
      <input [ngModel]="logradouroCdl?.cep" type="text" class="form-control" name="inputCepImovel" id="inputCepImovel"
        disabled>
    </div>

    <label for="inputBairroImovel" class="col-sm-2 control-label">Bairro</label>
    <div class="col-sm-3">
      <input type="text" class="form-control" name="inputBairroImovel" id="inputBairroImovel" disabled
        [ngModel]="logradouroCdl?.nomeBairro">
    </div>
  </div>
