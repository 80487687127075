
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpErrorResponse } from "@angular/common/http";
import { ExceptionBean } from '../models/ExceptionBean';


export function errorHandler(httpError: HttpErrorResponse) {
  let errMsg: string;
  if (httpError.error instanceof Error) {
    errMsg = `${httpError.error.message} - ${httpError.message}`;
  }
  else {
    errMsg = `${httpError.status} - ${httpError.message}`;

    if(Array.isArray(httpError.error)){
      let eb: ExceptionBean = httpError.error[0];
      return observableThrowError(`${eb.codigo}: ${eb.mensagem} ${eb.id ? `Valor: ${eb.id}` : ""}`);
    }
  }
  console.error(errMsg);
  return observableThrowError(`${errMsg} -- ${new Date().toISOString()}`);
}
