import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { GlobalModule } from '../../../global/global.module';
import { AppComponent } from './app.component';
import { EquipamentoPublicoDetalhesComponent } from './components/equipamento-publico-detalhes/equipamento-publico-detalhes.component';
import { routes } from './publico-routing.routes';


@NgModule({
  declarations: [
    AppComponent,
    EquipamentoPublicoDetalhesComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    GlobalModule,
    RouterModule.forRoot(routes),
    AccordionModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
